import type { App } from "~~/types"


export const useApps = () => {
    const apps: App[] = [
        { 
            label: 'onderwijsloket.com', 
            appIcon: 'brand/pwa_icon_onderwijsloket_kwjcnx', 
            url: 'https://onderwijsloket.com', 
            description: 'Het hoofdplatform van het Onderwijsloket, met informatie om je te oriënteren op de overstap naar het onderwijs. Het platfrom bestaat uit verschillende subsecties en tools, zoals de kennisbank, de routetool of de onderwijsnavigator.', 
            plausibleAuth: 'sg76yqKVdgArBQ3pgB8sK' 
        },
        { 
            label: 'voorbereiden.onderwijsloket.com', 
            appIcon: 'brand/pwa_icon_voorbereiden_v6srsv', 
            url: 'https://voorbereidenonderwijsloket.com', 
            description: 'Klaar voor de Stap is het platform om je voor te bereiden op de stap naar het onderwijs! Ontdek de gouden tips van overstappers die je voorgingen en leer je eigen valkuilen kennen, zodat je goed voorbereid voor de dag (of klas) komt.', 
            plausibleAuth: 'TgnGgwpqYjjIGoBNTJSqA' 
        },
        { 
            label: 'stats.onderwijsloket.com', 
            appIcon: 'brand/pwa_icon_onderwijsloket_kwjcnx', 
            url: 'https://stats.onderwijsloket.com', 
            description: 'Informatie en statistieken over de dienstverlening van het Onderwijsloket, samengevat in handige dashboards. Bevat daarnaast ook allerhande informatie over de beheoften en het gedrag van de doelgroep van het Onderwijsloket: de overstapper.',
            plausibleAuth: '6y2s-Bqz7Ov9q_P1OrXJP' 
        },
        { 
            label: 'indebuurt.onderwijs.in', 
            appIcon: 'brand/OnderwijsIn_LogoIcoon_Oranje_wexqnq', 
            url: 'https://indebuurt.onderwijs.in', 
            description: 'Leer het onderwijs bij jou in de buurt kennen en ontdek de mogelijkheden je daarop te oriënteren. Deze app is een bovenregionale zoekmachine: het bundelt het aanbod van regionale (maar ook landelijke) initiatieven, en maar die tegelijkertijd doorzoekbaar.', 
            demo: true, 
            plausibleAuth: 'oo2Pa0oxOIozumu1dy14L' 
        }
    ]

    const route = useRoute()
    const router = useRouter()

    const selectedApp = computed({
        get () {
            const index = apps.findIndex((item) => item.label === route.query.app)
            if (index === -1) {
                return 0
            }

            return index
        },
        set (value: number) {
            if (apps[value]) {
            // Hash is specified here to prevent the page from scrolling to the top
            router.replace({ query: { 
                ...route.query,
                app: apps[value].label
            } })
            } else if (apps[0]) {
                router.replace({ query: { ...route.query, app: apps[0].label } })
            }
        }
    })

    const app: Ref<App> = computed(() => apps[selectedApp.value] || apps[0] as App)

    return {
        apps,
        selectedApp,
        app
    }
}
